<template>
  <v-app id="app">
    <MainLayout />
    <FooterLayout />
  </v-app>
</template>

<script>
import MainLayout from '@/components/layouts/MainLayout.vue';
import FooterLayout from '@/components/layouts/FooterLayout.vue';

export default {
  components: {
    MainLayout,
    FooterLayout,
  },
  name: 'App',
  watch: {
    $route() {
      this.$nextTick(() => {
        this.$vuetify.goTo('.v-main', {
          duration: 500,
          offset: 0,
          easing: 'easeInOutCubic',
        });
      });
    },
  },
};
</script>
