import Vue from 'vue';
import './plugins/axios';
import vuetify from './plugins/vuetify';
import App from './App.vue';
import router from './router';
import store from './store';
import { i18n } from './plugins/i18n';
import VueGtm from 'vue-gtm';
import VueObserveVisibility from 'vue-observe-visibility';
import globalDirectives from './directives';
import './assets/styles/styles.scss';

Vue.use(VueObserveVisibility);
Vue.use(VueGtm, { id: 'GTM-NBDKT3F' });
Vue.config.productionTip = false;
Vue.use(globalDirectives);

router.beforeEach((to, from, next) => {
  const languages = ['en', 'gr'];
  if (languages.some(language => to.query.lang == language)) {
    i18n.locale = to.query.lang;
    Vue.axios.defaults.params = {
      lang: i18n.locale,
    };
  }

  return next();
});

router.beforeEach((to, from, next) => {
  if (to.query.code) {
    app = true;
    store.state.petition.code = to.query.code;
    return next({ name: 'Petition' });
  }

  if (!to.query.code && !app && !to.meta.allow) {
    return next({ name: 'Home' });
  }

  return next();
});

var app = new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App),
}).$mount('#app');
