export const gr = {
  'currentPackage': 'ΕΠΙΛΕΓΜΕΝΟ ΠΑΚΕΤΟ',
  'inCollaborationWith': 'Με τη συνεργασία',
  'getAnOffer': 'Πάρε προσφορά',  
  'back': 'Προηγούμενο',
  'next': 'Επόμενο ',
  'suffixSqm': 'τ.μ.',
  'add': 'Προσθήκη',
  'remove': 'Αφαιρεση',
  'send': 'Αποστολή',
  'email': 'Email',
  'gender': 'Φύλο',
  'male': 'Άνδρας',
  'female': 'Γυναίκα',
  'name': 'Όνομα',
  'surname': 'Επώνυμο',
  'fathersName': 'Όνομα Πατρός',
  'birthDate': 'Ημερομηνία Γέννησης',
  'phone': 'Κινητό τηλέφωνο',
  'afm': 'ΑΦΜ',
  'street': 'Οδός',
  'streetNumber': 'Αριθμός',
  'floor': 'Όροφος',
  'region': 'Περιοχή',
  'nextStep': 'Επόμενο βήμα',
  'previousStep': 'Προηγούμενο βήμα',
  homeInsurance: {
    'chooseYourProperty': 'Επιλέξτε την ασφάλιση ακινήτου',
    'needs': ' ανάλογα με τις ανάγκες σας.',
  },
  stepper: {
    'propertyDetails': 'Πληροφορίες ακινήτου',
    'ownerDetails': 'Στοιχεία ιδιοκτήτη',
    'giveUsDetailsAboutYou': 'Γράψε τα στοιχεία σου',
    'offerDetails': 'Δες την προσφορά',
    'requestSubmission': 'Στείλε την αίτηση ενδιαφέροντος',
    'step': 'Βήμα'
  },
  rules: {
    'required': 'Το πεδίο είναι υποχρεωτικό.',
    'EMB': 'Έγκυρη τιμή απο 30 μέχρι και 400 τετραγωνικά μέτρα',
    'amount': 'Έγκυρη τιμή απο {valueFrom}€ μέχρι και {valueTo}€',
    'afm': 'Μη έγκυρο ΑΦΜ.',
    'email': 'Μη έγκυρο e-mail.',
    'postalCode': 'Μη έγκυρος Ταχ. κώδικας',
    'year': 'Ο ιδιοκτήτης πρέπει να είναι άνω των 18.',
    'phone': 'Μη έγκυρος τηλεφωνικός αριθμός.',
    'specialCharacters': 'Το πεδίο δεν μπορεί να περιέχει ειδικούς χαρακτήρες.',
    'specialCharactersNumbers': 'Το πεδίο δεν μπορεί να περιέχει ειδικούς χαρακτήρες ή αριθμούς.',
    'percentage': 'Μη έγκυρο ποσοστό.',
    'percentageSum': 'To άθροισμα του ποσοστού ανά ιδιοκτήτη πρέπει να είναι 100.',
  },
  petition: {
    'tellUsAFewThingsAboutYourHouse': 'Πες μας μερικά πράγματα για <span class="d-block primary--text">το σπίτι σου</span>',
    'regionPlaceholder': 'π.χ. Αττική',
    'streetPlaceholder': 'π.χ. Κλεομένους',
    'streetNumberPlaceholder': 'π.χ. 2',
    'postalCode': 'Ταχ. κώδικας του ασφαλισμένου ακινήτου',
    'postalCodePlaceholder': 'π.χ. 15234',
    'mainSpacesArea': 'Εμβαδό κυρίων χώρων (σε τ.μ.)',
    'anyTimeCanInsureHousesMsg': 'Η Anytime μπορεί να ασφαλίσει σπίτια με εμβαδόν 30 έως 400 τ.μ.',
    'sqMetersPlaceholder': 'π.χ. 80',
    'buildYear': 'Έτος κατασκευής',
    'buildYearPlaceholder': 'π.χ. 1960',
    'homesBuildBeforeTooltip': 'Στην Anytime δεν μπορούν να ασφαλιστούν κατοικίες που έχουν κατασκευαστεί πριν το 1960.',
    'residenceType': 'Ο τύπος της κατοικίας είναι',
    'detachedHouse': 'Μονοκατοικία',
    'detachedHouseMsg': 'Η Μονοκατοικία αποτελείται από μία μόνο κατοικία, σε έναν ή περισσότερους ορόφους.',
    'blockOfFlats': 'Πολυκατοικία',
    'blockOfFlatsMsg': 'Η Πολυκατοικία αποτελείται από περισσότερα από ένα αυτόνομα διαμερίσματα.',
    'securityAmount': 'Ποσό ασφάλισης κτιρίου',
    'securityAmountTooltip': 'Το κεφάλαιο ασφάλισης για το κτίριο είναι από 950€/τ.μ έως 1.400 €/τ.μ',
    'securityAmountPlaceholder': 'π.χ. 123000',
    'insuredAmountBuildingRange': 'Το κεφάλαιο ασφάλισης για το κτίριο είναι από 1100€/τ.μ έως 1.600 €/τ.μ',
    'insuredAmount': 'Ποσό ασφάλισης περιεχομένου',
    'insuredAmountPlaceholder': 'e.g 1100',
    'insuredAmountTooltip': 'Το κεφάλαιο ασφάλισης για το περιεχόμενο είναι από 150€/τ.μ έως 450€/τ.μ',
    'exclusiveOffer': 'Αποκλειστική προσφορά',
    'seeAllCoverage': 'Δείτε όλες τις καλύψεις',
    'cardSubheader': 'Το ολοκληρωμένο πρόγραμμα ασφάλισης για κάθε κίνδυνο. Απευθύνεται σε εσάς που θέλετε να ασφαλίσετε την κατοικία σας για κάθε τυχαίο ζημιογόνο γεγονός.',
    'cardSubheaderContent': 'Το ολοκληρωμένο πρόγραμμα ασφάλισης για να προστατεύσετε το περιεχόμενο σας από κάθε κίνδυνο.',
    'cardSubheaderBuilding': 'Το ολοκληρωμένο πρόγραμμα ασφάλισης για να προστατεύσετε το κτίριο σας από κάθε κίνδυνο.',
    'selectedPackage': 'Επιλεγμένη ασφάλεια',
    'editBtn': 'Αλλαγή',
    'editPackageTitle': 'Διάλεξε  άλλο πακέτο',
    'choosePackage': 'Επιλέξτε πακέτο',
    'lossOfRentsTitle': 'Απώλεια μισθωμάτων',
    'buildingInsuranceTitle': 'Ασφάλεια κτιρίου',
    'contentsInsuranceTitle': 'Ασφάλεια περιεχομένου',
    'buildingContentSecurityTitle': 'Ασφάλεια κτιρίου & περιεχομένου',
    'lossOfRentsDetails': 'Απώλεια μισθωμάτων λόγω μη καταβολής τους από τον ενοικιαστή με την εκτέλεση της έξωσης',
    'buildingInsuranceDetails': 'Πυρκαγιά, πτώση κεραυνού, καπνός Έκρηξη',
    'contentInsuranceDetails': 'Πυρκαγιά, πτώση κεραυνού, καπνός Έκρηξη',
    'buildingContentSecurityDetails': 'Πυρκαγιά, πτώση κεραυνού, καπνός Έκρηξη',
  },
  coverage: {
    'Fire, lightning, smoke': 'Πυρκαγιά, πτώση κεραυνού, καπνός',
    'Explosion': 'Έκρηξη',
    'Terrorist acts': 'Τρομοκρατικές ενέργειες',
    'Malicious acts': 'Κακόβουλες ενέργειες',
    'Lock replacement': 'Αντικατάσταση κλειδαριάς',
    'Short circuit in building electrical installations and corresponding hazards': 'Βραχυκύκλωμα σε ηλεκτρολογικές εγκαταστάσεις κτιρίου και αντίστοιχοι κίνδυνοι',
    'Vehicle collision, aircraft crash, fall of trees, branches, poles': 'Πρόσκρουση οχήματος, πτώση αεροσκάφους, πτώση δένδρων, κλαδιών, στύλων',
    'Strikes, riots, political unrest': 'Στάσεις, απεργίες, οχλαγωγίες, πολιτικές ταραχές',
    'Coverage of damaged proportion of common and auxiliary spaces': 'Κάλυψη αναλογίας κοινόχρηστων και βοηθητικών χώρων',
    'Mortgage payment protection and continuation': 'Δόση στεγαστικού δανείου',
    'Costs of firefighting and damage reduction, costs of demolition, and removal of debris': 'Έξοδα πυρόσβεσης και περιορισμού ζημιάς, έξοδα καθαιρέσεων, κατεδαφίσεων και αποκομιδής ερειπίων μετά από ζημιά',
    'Costs of issuing building permits': 'Δαπάνες έκδοσης οικοδομικών αδειών',
    'Civil liability to third parties as a result of fire and explosion': 'Αστική ευθύνη έναντι τρίτων συνέπεια πυρκαγιάς και έκρηξης',
    'Damage to the building from attempted theft / robbery': 'Ζημιές στο κτίριο από προσπάθεια κλοπής/ληστείας',
    'Broken windows / mirrors': 'Θραύση τζαμιών/καθρεπτών',
    'General civil liability': 'Γενική αστική ευθύνη',
    'Any other destructive event': 'Κάθε άλλο τυχαίο ζημιογόνο γεγονός',
    'Personal accident': 'Προσωπικό ατύχημα',
    'Relocation costs': 'Δαπάνες μεταστέγασης',
    'Contents storage costs': 'Δαπάνες αποθήκευσης περιεχομένου',
    'Loss of rent income': 'Απώλεια ενοικίων',
    'Weather phenomenon': 'Καιρικά φαινόμενα',
    'Leaking / Breaking pipes': 'Διαρροή/Θραύση σωληνώσεων',
    'Third party liability as a result of weather events and leaking / broken pipes': 'Αστική ευθύνη έναντι τρίτων συνέπεια καιρικών φαινομένων και διαρροής/ θραύσης σωληνώσεων',
    'Earthquake': 'Σεισμός',
    'Damage to computers and related equipment': 'Ζημιές ηλεκτρονικών υπολογιστών και σχετικού εξοπλισμού',
    'Theft after burglary / robbery of content': 'Κλοπή μετά από διάρρηξη/ληστεία περιεχομένου',
    'Short circuit in electrical and electronic home appliances and corresponding hazards': 'Βραχυκύκλωμα σε ηλεκτρικές και ηλεκτρονικές οικιακές συσκευές και αντίστοιχοι κίνδυνοι',
  },
  floors: {},
  orderPlaced: {
    'thankYou': 'Το αίτημά σου έχει σταλεί <span class="d-block">με <span class="primary--text">επιτυχία</span>!</span>',
    'yourRequestHasBeenSent': 'Θα λάβεις ένα ενημερωτικό email από την Anytime με το ασφαλιστήριο συμβόλαιο και τους διαθέσιμους τρόπους πληρωμής. Η κάλυψή σου ξεκινά με την πληρωμή των ασφαλίστρων.',
    'backToProsperty': 'Πίσω στην Prosperty',
  },
  checkOut: {
    'yourOfferIsReady': 'Η προσφορά σου είναι <span class="d-block primary--text">έτοιμη<span>!',
    'addLegalProtection': 'Πρόσθεσε και τη νομική προστασία!',
    'acceptanceOfTerms': 'Αποδοχή όρων σύμβασης',
    'iDeclareThat': 'Δηλώνω ότι',
    'thePropertyIsLeasedToAThirdParty': 'Το ακίνητο διατίθεται με μίσθωση σε τρίτο και βρίσκεται εντός Ελληνικής επικράτειας.',
    'thePropertyIsNotAvailableToAThirdParty': 'Το ακίνητο δεν διατίθεται σε τρίτο για επαγγελματική χρήση.',
    'thePropertyIsNotAvailableForShortTermLease': 'Το ακίνητο δεν διατίθεται για βραχυχρόνια μίσθωση μικρότερη των δύο μηνών.',
    'theRentOfThePropertyHasBeenDeclaredTaxOffice': 'Το μίσθωμα του ακινήτου έχει δηλωθεί στην Εφορία.',
    'theRealEstateLeaseContractTerminated': 'Το συμβόλαιο μίσθωσης ακινήτου δεν έχει διακοπεί και το μίσθωμα καταβάλλεται κατά τον χρόνο που έχει οριστεί.',
    'thePropertyHasBeenConstructedWithLegalPermit': 'Το ακίνητο έχει κατασκευαστεί με νόμιμη άδεια και δε νομιμοποιήθηκε εκ των υστέρων.',
    'theFrameOfTheProperty': 'Ο σκελετός του ακινήτου είναι κατασκευασμένος από μπετόν αρμέ.',
    'thereIsNotPreviousDamageToTheProperty': 'Στο ακίνητο δεν υπάρχουν προηγούμενες ζημιές από τους κινδύνους που ζητάω να ασφαλίσω. Σε περίπτωση που υπήρχαν στο παρελθόν, δηλώνω ότι έχουν αποκατασταθεί με τον τεχνικά ενδεδειγμένο τρόπο.',
    'theHouseDoesNotRemainVacant': 'Η κατοικία δεν μένει ακατοίκητη περισσότερο από ενενήντα (90) συνεχόμενες μέρες.',
    'thePropertyIsNotAvailableToTenants': 'Το ακίνητο δεν διατίθεται σε τρίτους με βραχυχρόνια μίσθωση μικρότερη των δύο μηνών.',
    'iHaveReadAndAccept': 'διάβασα και αποδέχομαι',
    'their': 'τους',
    'insuranceTerms': 'Όρους Ασφάλισης',
    'the': 'το',
    'prospectus': 'Ενημερωτικό Έντυπο',
    'andIHaveBeenInformedAboutTheCharacteristics': 'και ενημερώθηκα για τα χαρακτηριστικά του ασφαλιστικού προϊόντος από το',
    'informationDocument': 'Έγγραφο Πληροφοριών',
    'iConfirmThatAllTheAboveInformation': 'Επιβεβαιώνω ότι όλες οι παραπάνω πληροφορίες που παρέχονται από την πλευρά μου είναι εξακριβωμένες και πλήρεις. Δηλώνω ότι επιθυμώ να λαμβάνω τις πληροφορίες σχετικά με το ασφαλιστήριο συμβόλαιο ηλεκτρονικά.',
    'throughProspectusForTheProcessingOfPersonalData': ' Μέσω του <span class="font-weight-bold">Ενημερωτικού Εντύπου για την Επεξεργασία των Δεδομένων Προσωπικού Χαρακτήρα,</span> που βρίσκεται μόνιμα αναρτημένο <a target="_blank" class="text-decoration-underline terms--text check-out-text" href="https://www.anytime.gr/online/wcm/connect/www.anytime.gr-11409/5a754ac8-4c3c-454a-9dc1-5a9f89082cf8/anytime_enimerotiko+entypo+proswpikwn+dedomenon.pdf?MOD=AJPERES&CVID=nzk-MFZ&CVID=nzkPtEp">εδώ</a>, πληροφορήθηκα μεταξύ άλλων για τον τρόπο, τους σκοπούς επεξεργασίας των δεδομένων μου, τους τυχόν αποδέκτες και τα δικαιώματα που έχω και μπορώ να ασκήσω.',
    'iGiveMyExplicitConsent': ' Παρέχω τη ρητή συγκατάθεσή μου (σύμφωνα με το Άρθρο 7 του Κανονισμού (ΕΕ) 2016/679) στην ΙΝΤΕΡΑΜΕΡΙΚΑΝ και στις άλλες εταιρίες του Ομίλου ΙΝΤΕΡΑΜΕΡΙΚΑΝ, για την επεξεργασία των  απλών δεδομένων μου για εμπορικούς, προωθητικούς ή ερευνητικούς σκοπούς, διατηρώντας ανά πάσα στιγμή το δικαίωμα ανάκλησής της αποστέλλοντας email στο dpo@interamerican.gr',
    'mandatoryInformationFromLawNumber': 'Υποχρεωτική εκ του N. 4583/2018 ενημέρωση από τον ασφαλιστικό διαμεσολαβητή σου. Διάβασε το Έντυπο Προσυμβατικής Ενημέρωσης',
    'here': 'εδω',
    'sendApplication': 'Αποστολή αίτησης',
    'youNeedToAcceptTheTerms': 'Παρακαλούμε, αποδεχτήτε τους όρους.',
    'annualPremiumConst': 'Ετήσιο κόστος ασφαλίστρου',
  },
  advisorForm: {
    'tellUsAFewThingsAboutYourself': 'Πες μας μερικά πράγματα για <span class="d-block primary--text">σένα</span>',
    'contractingDetails': 'Στοιχεία συμβαλλόμενου',
    'whoWillBeTheContractingParty': ' Ποιός / Ποιά θα είναι ο Συμβαλλόμενος στο συμβόλαιο;',
    'ownershipPercentage': 'Ποσοστό ιδιοκτησίας',
    'other': 'Άλλος',
    'email': 'Email συμβαλλόμενου',
    'ownerNumber': 'Αριθμός Ιδιοκτητών',
    'ownerNumberLoss': 'Αριθμός Εκμισθωτών ',
    'pleaseFillOutTheForm': 'Παρακαλούμε, συμπληρώστε τα στοιχεία της φόρμας',
    'accordingToYourNeeds': 'Σύμφωνα με τις ανάγκες σας που αναλύθηκαν από τις απαντήσεις σας, σας προτείνουμε το συγκεκριμένο προϊόν ως το πλέον κατάλληλο για εσάς.',
    'errorThereWasAProblemWithTheServer': 'Υπήρξε πρόβλημα με το αίτημα σας παρακαλώ επικοινωνήστε με τον διαχειριστή.',
  },
  floorSelect: {
    floors1: '1ο ΥΠΟΓΕΙΟ',
    floors2: '2ο ΥΠΟΓΕΙΟ',
    floors3: 'ΥΠΟΓΕΙΟ',
    floors4: 'ΗΜΙ-ΥΠΟΓΕΙΟ',
    floors5: 'ΗΜΙ-ΙΣΟΓΕΙΟ',
    floors6: 'ΙΣΟΓΕΙΟ',
    floors7: 'ΗΜΙΟΡΟΦΟΣ',
    floors8: 'ΑΝΩΓΕΙΟ',
    floors9: 'ΔΩΜΑ',
    floors10: 'ΠΑΤΑΡΙ',
    floors11: '1ο ΟΡΟΦΟ',
    floors12: '2ο ΟΡΟΦΟ',
    floors13: '3ο ΟΡΟΦΟ',
    floors14: '4ο ΟΡΟΦΟ',
    floors15: '5ο ΟΡΟΦΟ',
    floors16: '6ο ΟΡΟΦΟ',
    floors17: '7ο ΟΡΟΦΟ',
    floors18: '8ο ΟΡΟΦΟ',
    floors19: '9ο ΟΡΟΦΟ',
    floors20: '10ο ΟΡΟΦΟ',
    floors21: 'ΣΕ ΟΛΗ ΤΗΝ ΟΙΚΟΔΟΜΗ',
  },
};
