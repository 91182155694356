import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { en } from '../translations/en';
import { gr } from '../translations/gr';
Vue.use(VueI18n);

const messages = { en, gr };

export const i18n = new VueI18n({
  locale: 'gr', // set locale
  fallbackLocale: 'gr',
  messages, // set locale messages
});
