const formatPriceDirective = {
  bind(el) {
    el.innerHTML = parseFloat(el.innerHTML).toLocaleString('el', {
      style: 'currency',
      currency: 'EUR',
    });
  },
};

export default formatPriceDirective;
