import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Petition.vue'),
    meta: {
      allow: true,
    },
  },
  {
    path: '/:code',
    component: () => import('../views/Petition.vue'),
    meta: {
      allow: true,
    },
  },
  {
    path: '/insurances',
    name: 'Insurance',
    component: () => import('../views/Petition.vue'),
    meta: {
      allow: true,
    },
  },
  {
    path: '/home-insurances',
    name: 'HomeInsurance',
    component: () => import('../views/HomeInsurance.vue'),
    meta: {
      allow: true,
    },
  },
  {
    path: '/petition',
    name: 'Petition',
    component: () => import('../views/Petition.vue'),
  },
  {
    path: '/about-property',
    name: 'AboutProperty',
    component: () => import('../views/AboutProperty.vue'),
  },
  {
    path: '/about-owner/:user',
    name: 'AboutOwner',
    component: () => import('../views/AboutOwner.vue'),
  },
  {
    path: '/about-advisor',
    name: 'AboutAdvisor',
    component: () => import('../views/AboutAdvisor.vue'),
  },
  {
    path: '/advisor-data',
    name: 'AdvisorData',
    component: () => import('../views/AdvisorData.vue'),
  },
  {
    path: '/checkout',
    name: 'Checkout',
    component: () => import('../views/Checkout.vue'),
  },
  {
    path: '/order-placed',
    name: 'OrderPlaced',
    component: () => import('../views/OrderPlaced.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
