import Vue from 'vue';
import Vuetify from 'vuetify/lib';

const lightTheme = {
  primary: '#613CF8',
  secondary: '#49D69F',
  accent: '#2C2945',
  error: '#b71c1c',
  gray: '#ECEFF1',
  checks: '#D1D5D9',
  terms: '#5C5C61',
  checkout: '#257E5C',
  white: '#FFFFFF',
  'purple-dark': '#302479',
  'purple-dim': '#6C6FB7',
  'purple-light': '#F0EDFF',
  'green-main': '#129B73',
  'grey-light': '#F7F9FA',
  'grey-vivid': '#D6DFE7',
  'green-muted': '#F0FCF7',
};

const opts = {
  defaultAssets: {
    font: {
      family: 'Manrope',
    },
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: lightTheme,
    },
  },
};

Vue.use(Vuetify, opts);

export default new Vuetify(opts);
