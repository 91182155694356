const formatPriceInputDirective = {
  bind(el, binding, vnode) {
    el.querySelector('input').value = el
      .querySelector('input')
      .value.toString()
      .replace(/\D/g, '')
      .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    vnode.componentInstance.$emit('input', el.querySelector('input').value);
  },
  inserted(el, binding, vnode) {
    el.querySelector('input').value = el
      .querySelector('input')
      .value.toString()
      .replace(/\D/g, '')
      .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    vnode.componentInstance.$emit('input', el.querySelector('input').value);
  },
  update(el, binding, vnode) {
    el.querySelector('input').value = el
      .querySelector('input')
      .value.toString()
      .replace(/\D/g, '')
      .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    vnode.componentInstance.$emit('input', el.querySelector('input').value);
  },
  componentUpdated(el, binding, vnode) {
    el.querySelector('input').value = el
      .querySelector('input')
      .value.toString()
      .replace(/\D/g, '')
      .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    vnode.componentInstance.$emit('input', el.querySelector('input').value);
  },
};

export default formatPriceInputDirective;
