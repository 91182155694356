import uppercaseDirective from './upperCase';
import formatPriceInputDirective from './formatPriceInput';
import formatPriceDirective from './formatPrice';

export default {
  install(Vue) {
    Vue.directive('uppercase', uppercaseDirective),
    Vue.directive('format-price-input', formatPriceInputDirective),
    Vue.directive('format-price', formatPriceDirective);
  },
};
