import Vue from 'vue';
import Vuex from 'vuex';
import dayjs from 'dayjs';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loading: false,
    step: 1,
    price: '',
    priceProtection: '',
    petition: {
      renter: 1,
      startDate: dayjs().format('YYYY-MM-DD'),
      endDate: dayjs()
        .add(1, 'year')
        .format('YYYY-MM-DD'),
      hasProtection: false,
      code: null,
      title: '',
      resortType: '1',
      resort: {
        ownerNumber: '',
        owners: [
          {
            amount: 0,
          },
          {
            amount: 0,
          },
          {
            amount: 0,
          },
        ],
        streetName: '',
        streetNumber: '',
        nrOfFloors: '',
        city: '',
        contracting: {},
        contracting_email: '',
      },
      postalCode: '',
      sqMeters: '',
      builtYear: '',
      pricePerSqMeter: '',
      priceContent: '',
    },
  },
  mutations: {
    setPetition(state, payload) {
      state.petition = payload;
    },

    moveToNextStep(state) {
      if (state.step < 4) {
        state.step++;
      }
    },

    moveToPreviousStep(state) {
      if (state.step > 1) {
        state.step--;
      }
    },

    setPrice(state, payload) {
      state.price = payload;
    },

    setPriceProtection(state, payload) {
      state.priceProtection = payload;
    },

    setLoading(state, payload) {
      state.loading = payload;
    },
  },
  actions: {},
  getters: {
    petition: state => {
      return state.petition;
    },

    price: state => {
      return state.price;
    },

    step: state => {
      return state.step;
    },

    priceProtection: state => {
      return state.priceProtection;
    },

    loading: state => {
      return state.loading;
    },
  },
  modules: {},
});
