<template>
  <v-main>
    <v-row
      fluid
      fill-height
      class="white align-baseline justify-center full-width"
    >
      <router-view :key="$route.path" />
    </v-row>
  </v-main>
</template>

<script>
export default {
  name: 'MainLayout',
};
</script>