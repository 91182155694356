export const en = {
  'currentPackage': 'current package',
  'inCollaborationWith': 'In collaboration with',
  'getAnOffer': 'Get an offer',
  'back': 'Back',
  'next': 'Next ',
  'suffixSqm': 'sq.m.',
  'add': 'Add',
  'remove': 'Remove',
  'send': 'Send',
  'email': 'Email',
  'gender': 'Gender',
  'male': 'Male',
  'female': 'Female',
  'name': 'Name',
  'surname': 'Surname',
  'fathersName': 'Father`s Name',
  'birthDate': 'Date Of Birth',
  'phone': 'Mobile phone',
  'afm': 'VAT Number',
  'street': 'Address',
  'streetNumber': 'Number',
  'floor': 'Floor',
  'region': 'Region',
  'nextStep': 'Next step',
  'previousStep': 'Previous step',
  homeInsurance: {
    'chooseYourProperty': 'Choose your property insurance according to your ',
    'needs': 'needs.',
  },
  stepper: {
    'propertyDetails': 'Property details',
    'ownerDetails': 'Owner’s details',
    'giveUsDetailsAboutYou': 'Give us details about you',
    'offerDetails': 'Offer details',
    'requestSubmission': 'Request submission',
    'step': 'Step'
  },
  rules: {
    'required': 'This field is required',
    'EMB': 'Valid acreage from 30 to 400 square meters',
    'amount': 'Valid amount from {valueFrom}€ to {valueTo}€',
    'afm': 'Invalid VAT number.',
    'email': 'Invalid e-mail.',
    'postalCode': 'Invalid postal code.',
    'year': 'Owner should at least be 18 years old.',
    'phone': 'Invalid phone number.',
    'specialCharacters': 'This field does not accept special characters.',
    'specialCharactersNumbers': 'This field does not accept special characters or numbers.',
    'percentage': 'Invalid percentage.',
    'percentageSum': 'The summary of the owner`s percentages has to be 100.',
  },
  petition: {
    'tellUsAFewThingsAboutYourHouse': 'Tell us a few things about <span class="d-block primary--text">your property</span>',
    'regionPlaceholder': 'e.g. Attica',
    'streetPlaceholder': 'e.g. Kleomenous',
    'streetNumberPlaceholder': 'e.g. 2',
    'postalCode': 'Postal code',
    'postalCodePlaceholder': 'e.g. 15234',
    'mainSpacesArea': 'Main spaces area',
    'anyTimeCanInsureHousesMsg': 'Anytime can insure houses with an area of 30 to 400 sq.m.',
    'sqMetersPlaceholder': 'e.g. 80',
    'buildYear': 'Construction year',
    'buildYearPlaceholder': 'e.g. 1960',
    'homesBuildBeforeTooltip': 'Homes built before 1960 cannot be insured at Anytime.',
    'residenceType': 'Residence type',
    'detachedHouse': 'Single house',
    'detachedHouseMsg': 'The single house consists of a single dwelling, on one or more floors.',
    'blockOfFlats': 'Block of flats',
    'blockOfFlatsMsg': 'The block of flats consists of more than one independent apartments.',
    'securityAmount': 'Security amount',
    'securityAmountTooltip': 'The insured amount for the building ranges from 950 € / sq.m to 1400 € / sq.m maximum',
    'securityAmountPlaceholder': 'e.g 123000',
    'insuredAmountBuildingRange': 'The insured amount for the building ranges from 1100 € / sq.m to 1600 € / sq.m maximum',
    'insuredAmount': 'Insured amount',
    'insuredAmountPlaceholder': 'e.g 1100',
    'insuredAmountTooltip': 'The insured amount for the contents ranges from 150 € / sq.m to 450 € / sq.m maximum',
    'exclusiveOffer': 'Exclusive Offer',
    'seeAllCoverage': 'See all coverage',
    'cardSubheader': 'Comprehensive insurance cover to mitigate risk and loss from accidental damage.',
    'cardSubheaderContent': 'The comprehensive insurance program to protect your content from any risk.',
    'cardSubheaderBuilding': 'The comprehensive insurance program to protect your building from any risk.',
    'selectedPackage': 'Selected package',
    'editBtn': 'Edit',
    'editPackageTitle': 'Choose a different package',
    'choosePackage': 'Choose package',
    'lossOfRentsTitle': 'Loss of Rents',
    'buildingInsuranceTitle': 'Building Insurance',
    'contentsInsuranceTitle': 'Contents Insurance',
    'buildingContentSecurityTitle': 'Building & content security',
    'lossOfRentsDetails': 'Loss of rents due to non-payment by the tenant upon execution of the eviction',
    'buildingInsuranceDetails': 'Fire, lightning, smoke, explosion',
    'contentInsuranceDetails': 'Fire, lightning, smoke, explosion',
    'buildingContentSecurityDetails': 'Fire, lightning, smoke, explosion',
  },
  coverage: {
    'Fire, lightning, smoke': 'Fire, lightning, smoke',
    'Explosion': 'Explosion',
    'Terrorist acts': 'Terrorist acts',
    'Malicious acts': 'Malicious acts',
    'Lock replacement': 'Lock replacement',
    'Short circuit in building electrical installations and corresponding hazards': 'Short circuit in building electrical installations and corresponding hazards',
    'Vehicle collision, aircraft crash, fall of trees, branches, poles': 'Vehicle collision, aircraft crash, fall of trees, branches, poles',
    'Strikes, riots, political unrest': 'Strikes, riots, political unrest',
    'Coverage of damaged proportion of common and auxiliary spaces': 'Coverage of damaged proportion of common and auxiliary spaces',
    'Mortgage payment protection and continuation': 'Mortgage payment protection and continuation',
    'Costs of firefighting and damage reduction, costs of demolition, and removal of debris': 'Costs of firefighting and damage reduction, costs of demolition, and removal of debris',
    'Costs of issuing building permits': 'Costs of issuing building permits',
    'Civil liability to third parties as a result of fire and explosion': 'Civil liability to third parties as a result of fire and explosion',
    'Damage to the building from attempted theft / robbery': 'Damage to the building from attempted theft / robbery',
    'Broken windows / mirrors': 'Broken windows / mirrors',
    'General civil liability': 'General civil liability',
    'Any other destructive event': 'Any other destructive event',
    'Personal accident': 'Personal accident',
    'Relocation costs': 'Relocation costs',
    'Contents storage costs': 'Contents storage costs',
    'Loss of rent income': 'Loss of rent income',
    'Weather phenomenon': 'Weather phenomenon',
    'Leaking / Breaking pipes': 'Leaking / Breaking pipes',
    'Third party liability as a result of weather events and leaking / broken pipes': 'Third party liability as a result of weather events and leaking / broken pipes',
    'Earthquake': 'Earthquake',
    'Damage to computers and related equipment': 'Damage to computers and related equipment',
    'Theft after burglary / robbery of content': 'Theft after burglary / robbery of content',
    'Short circuit in electrical and electronic home appliances and corresponding hazards': 'Short circuit in electrical and electronic home appliances and corresponding hazards',
  },
  floors: {},
  orderPlaced: {
    'thankYou': 'Your request has been sent <span class="d-block"><span class="primary--text">successfully</span>!</span>',
    'yourRequestHasBeenSent': 'Your request has been sent successfully. You will receive an informative email from Anytime with your insurance policy and available payment methods. Your coverage begins with the payment of premiums.',
    'backToProsperty': 'Back to Prosperty',
  },
  checkOut: {
    'yourOfferIsReady': 'Your offer is <span class="primary--text">ready<span>!',
    'addLegalProtection': 'Add legal protection!',
    'acceptanceOfTerms': 'Acceptance of terms',
    'iDeclareThat': 'I declare that',
    'thePropertyIsLeasedToAThirdParty': 'The property is leased to a third party and is located within Greek territory.',
    'thePropertyIsNotAvailableToAThirdParty': 'The property is not available to a third party for commercial use.',
    'thePropertyIsNotAvailableForShortTermLease': 'The property is not available for short-term lease less than two months.',
    'theRentOfThePropertyHasBeenDeclaredTaxOffice': 'The rent of the property has been declared to the Tax Office.',
    'theRealEstateLeaseContractTerminated': 'the real estate lease contract has not been terminated and the rent is paid at the appointed time.',
    'thePropertyHasBeenConstructedWithLegalPermit': 'The property has been constructed with a legal permit and was not legalized afterwards.',
    'theFrameOfTheProperty': 'The frame of the property is made of reinforced concrete.',
    'thereIsNotPreviousDamageToTheProperty': 'There is no previous damage to the property from the risks I ask to insure. In case they existed in the past, I declare that they have been restored in the technically appropriate way.',
    'theHouseDoesNotRemainVacant': 'The house does not remain vacant for more than ninety (90) consecutive days.',
    'thePropertyIsNotAvailableToTenants': 'The property is not available to tenants with a short-term lease of less than two months.',
    'iHaveReadAndAccept': 'I have read and accept',
    'their': 'the',
    'insuranceTerms': 'Insurance Terms',
    'the': 'the',
    'prospectus': 'Prospectus',
    'andIHaveBeenInformedAboutTheCharacteristics': 'and I have been informed about the characteristics of the insurance product by the',
    'informationDocument': 'Information Document',
    'iConfirmThatAllTheAboveInformation': 'I confirm that all the above information provided by me is true and complete. I declare that I wish to receive the information about the insurance policy electronically.',
    'throughProspectusForTheProcessingOfPersonalData': 'Through Prospectus for the Processing of Personal Data Character, which is permanently posted <a target="_blank" class="text-decoration-underline terms--text check-out-text" href="https://www.anytime.gr/online/wcm/connect/www.anytime.gr-11409/5a754ac8-4c3c-454a-9dc1-5a9f89082cf8/anytime_enimerotiko+entypo+proswpikwn+dedomenon.pdf?MOD=AJPERES&CVID=nzk-MFZ&CVID=nzkPtEp">here</a>, I was informed of my rights, for the purposes of processing my data, and any recipients and the rights I have and can exercise.',
    'iGiveMyExplicitConsent': ' I give my explicit consent (according to Article 7 of Regulation (EU) 2016/679) to INTERAMERICAN and the other companies of the INTERAMERICAN Group, for the processing of my simple data for commercial, promotional or research purposes, maintaining at all times right to revoke it by sending an email to dpo@interamerican.gr',
    'mandatoryInformationFromLawNumber': ' Mandatory information from N. 4583/2018 from the insurance company your mediator. Read the Pre-Contract Information Form ',
    'here': 'here',
    'sendApplication': 'Send application',
    'youNeedToAcceptTheTerms': 'You need to accept the Terms of service before we continue.',
    'annualPremiumConst': 'Annual premium cost',
  },
  advisorForm: {
    'tellUsAFewThingsAboutYourself': 'Tell us a few things about <span class="d-block primary--text">yourself</span>',
    'contractingDetails': 'Contracting details',
    'whoWillBeTheContractingParty': ' Who will be the Contracting Party?',
    'ownershipPercentage': 'Ownership percentage',
    'other': 'Other',
    'email': 'Email',
    'ownerNumber': 'Number of Owners',
    'ownerNumberLoss': 'Number of Landlords ',
    'pleaseFillOutTheForm': 'Please, fill out the form.',
    'accordingToYourNeeds': 'According to your needs analyzed by your answers, we recommend this product as the most suitable for you.',
    'errorThereWasAProblemWithTheServer': 'There was a problem with the server please contact the app administrator.',
  },
  floorSelect: {
    floors1: '1st BASEMENT',
    floors2: '2nd BASEMENT',
    floors3: 'BASEMENT',
    floors4: 'HALF-BASEMENT',
    floors5: 'HALF-GROUND FLOOR',
    floors6: 'GROUND FLOOR',
    floors7: 'HALF-FLOOR',
    floors8: 'UPSTAIRS',
    floors9: 'ROOM',
    floors10: 'LOFT',
    floors11: '1st FLOOR',
    floors12: '2nd FLOOR',
    floors13: '3rd FLOOR',
    floors14: '4th FLOOR',
    floors15: '5th FLOOR',
    floors16: '6th FLOOR',
    floors17: '7th FLOOR',
    floors18: '8th FLOOR',
    floors19: '9th FLOOR',
    floors20: '10th FLOOR',
    floors21: 'THROUGHOUT THE BUILDING',
  },
};
