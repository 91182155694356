<template>
  <v-footer
    height="75"
    class="d-flex justify-center px-0"
    color="#302479"
    dark
    absolute
    app
  >
    <div class="font-size-14 font-weight-medium d-flex align-center">
      <span class="mr-3">{{ $t('inCollaborationWith') }}</span>
      <div>
        <a :href="symeonidisNikosUrl" target="_blank">
          <v-img
            :max-width="$vuetify.breakpoint.mdAndUp ? '200' : '180'"
            src="/logos/symeonidis.png"
          />
        </a>
      </div>
    </div>
  </v-footer>
</template>

<script>
import { SYMEONIDIS_NIKOS_URL } from '@/config/app';

export default {
  name: 'FooterLayout',
  data() {
    return {
      symeonidisNikosUrl: SYMEONIDIS_NIKOS_URL,
    };
  },
};
</script>
