const uppercaseDirective = {
  bind(el, binding, vnode) {
    el.querySelector('input').value = el
      .querySelector('input')
      .value.toUpperCase();
    vnode.componentInstance.$emit('input', el.querySelector('input').value);
  },
  inserted(el, binding, vnode) {
    el.querySelector('input').value = el
      .querySelector('input')
      .value.toUpperCase();
    vnode.componentInstance.$emit('input', el.querySelector('input').value);
  },
  update(el, binding, vnode) {
    el.querySelector('input').value = el
      .querySelector('input')
      .value.toUpperCase();
    vnode.componentInstance.$emit('input', el.querySelector('input').value);
  },
  componentUpdated(el, binding, vnode) {
    el.querySelector('input').value = el
      .querySelector('input')
      .value.toUpperCase();
    vnode.componentInstance.$emit('input', el.querySelector('input').value);
  },
};

export default uppercaseDirective;
